import React, { useRef, useState } from 'react'
import * as THREE from 'three'
import { OrbitControls } from '@react-three/drei'
import { Canvas, useFrame } from '@react-three/fiber'
import "./App.css"

function rColor(color){
	if(color=="#ff1000")	return "#0001ff";//red to blue
	if(color=="#008100") 	return "#800081";//green to purple
	if(color=="#0001ff")	return "#008100";//blue to green
	if(color=="#800081")     return "#ff1000";//purple to red
	if(color=="grey")       return "#ff1000";//grey to red
	return color
}

function lockColor(color){
	if(color==="#ff1000")	return 'red'
	if(color==="#008100")	return 'green'
	if(color==="#0001ff")	return 'blue'
	if(color==="#800081")	return 'purple'
	return color
}
	
function getB(map,x,y,z) {
	if (map[x]==null)
		return null;
	if (map[x][y]==null)
		return null;
	return map[x][y][z];
}

function setB(map,x,y,z,color) {
	if (map[x]==null)
		map[x]=[]
	if (map[x][y]==null)
		map[x][y]=[]
	map[x][y][z]=color
}

function Box(props) {
	const mesh = useRef(null)
	const [color, setColor] = useState(props.color)
	if(color==="black"){
		return (<mesh
		{...props}
		ref={mesh}>
		<boxGeometry args={[1.01,1.01,1.01]}/>
		<meshStandardMaterial color={"black"}/>
		</mesh>)
	}
	else{
	return (
	 	<mesh
		{...props}
		onTouchStart={(event)=>{
			if(event?.touches>1){
				setColor(lockColor(color))
			}
			else{
				setColor(rColor(color))
			}
			event.stopPropagation()
		}}
		onClick={(event)=>{
			setColor(rColor(color))
			event.stopPropagation()
		}}
		onContextMenu={(event=>{
			setColor(lockColor(color))
			event.stopPropagation()
		})}
		ref={mesh}>
		<boxGeometry args={[0.8, 0.8, 0.8]}/>
		<meshStandardMaterial color={color}/>
		</mesh>
	)
	}
}

function App() {
  let x = [1,2,3,4]
  let map=[];
	
	for (let i in x){
		for(let j in x){
			for(let k in x){
				if((i==='1'||i==='2')&&(j==='1'||j==='2')&&(k==='2'||k==='1')){
				setB(map,i,j,k,"black")
				}
				else{
				setB(map,i,j,k,"grey")
				}
			}
		}
	}
	map[0][3][3]="red"
	map[2][2][3]="blue"
	map[1][1][3]="green"
	map[3][0][3]="purple"
	map[1][3][1]="red"
	map[0][3][1]="green"
	map[0][0][2]="green"
	map[2][0][2]="blue"
	map[2][0][1]="purple"
	map[1][0][0]="blue"
	map[3][3][0]="red"
	map[2][2][0]="purple"
	map[1][1][0]="red"
  return (

	<div className="App">
  	<Canvas>
	  <group>
	  <OrbitControls makeDefault>
	  <pointLight/>
	  </OrbitControls>
	  </group>
	  <ambientLight intensity={0.5}/>
	  <pointLight position={[9,9,9]}/>
	  <pointLight position={[-9,-9,9]}/>
	  {map.map((xmap,i)=>{ return xmap.map((ymap,j)=>{return ymap.map((zmap,k)=>{return <Box position={[i-1.5,j-1.5,k-1.5]} color={getB(map,i,j,k)} geom={[0.8,0.8,0.8]}/>})})})}
	  </Canvas>
	</div>
  );
}

export default App;
